import React from 'react'

export default function Footer() {
  return (
    <section id="footer" data-page="Footer">
    <div className="main-footer">
    <div className="com " data-aos="fade-up">
    <img src="/images/symboles/UrbanPillar-Identity.png" alt="urbanpillar footer logo" className="footer-logo"/>
    <p>
       UrbanPillar is Maharashtra’s most comprehensive property destination, providing you with everything you need to stay on top of your search for your dream home.
    </p>
    <h5>FOLLOW</h5>
     <div className="sociallogos">
        <div className="logobox">
            <a href="https://www.linkedin.com/company/68245912" aria-label="social media link"><img src="/images/symboles/linkedin.svg" className="i" alt="urbanpillar linkedin" /></a>
            <a href="https://www.youtube.com/@urbanpillar6468" aria-label="social media link"><img src="/images/symboles/youtube.svg" className="i" alt="urbanpillar youtube" /></a>
            <a href="https://www.facebook.com/UrbanPillar/" aria-label="social media link"><img src="/images/symboles/facebook.svg" className="i" alt="urbanpillar facebook" /></a>
            <a href="https://www.instagram.com/urbanpillarofficial/" aria-label="social media link"><img src="/images/symboles/instagram.svg" className="i" alt="urbanpillar instagram" /></a>
        </div>
    </div>
  </div>
  <div className="com" data-aos="fade-up">
    <div className="fs-4 fw-5 my-2">Company</div>
    <ul>
        <li>
            <a href="/about" aria-label="Urbanpillar-footer">About Us</a>
        </li>
        <li>
            <a href="/terms_condition" aria-label="Urbanpillar-footer">Privacy Policy</a>
        </li>
        <li>
            <a href="/terms_condition" aria-label="Urbanpillar-footer">Terms & Condition</a>
        </li>
    </ul>
   
  </div>
  <div className="com" data-aos="fade-up">
    <div className="fs-4 fw-5 my-2">Explore</div>
    <ul>
        <li>
            <a href="https://urbanpillar.com/news/" aria-label="Urbanpillar-footer">News</a>
        </li>
        <li>
            <a href="/testimonials" aria-label="Urbanpillar-footer">Testimonial </a>
        </li>
        <li>
            <a href="/tools/Eligibility%20Calculator" aria-label="Urbanpillar-footer">Home Loan</a>
        </li>
        <li>
            <a href="/contactus" aria-label="Urbanpillar-footer">Sales Enquiry</a>
        </li>
    </ul>
   
  </div>
  <div className="com " data-aos="fade-up">
    <div className="fs-4 fw-5 my-2">Contact Us</div>
        <ul>
          <li> <span className="material-icons">
            location_on
            </span> <span>UrbanPillar India</span></li>
                  <li><a href="tel:+91 7400199996"><span className="material-icons">
            call
            </span> <span>+91 7400199996</span></a></li>
                  <li><a href="mailto:info@urbanpillar.com">
                     <span className="material-icons">
            email
            </span>
            <span>info@urbanpillar.com</span></a></li>
                  <li><a href="/contactus">
                      <span className="material-icons-outlined round-arrow">
            navigate_next
            </span><span>Contact Us</span></a></li>
        </ul>
  </div>
  </div>
<footer><small>Copyright © 2024 UrbanPillar All Rights Reserved </small></footer>
</section>
  )
}
