import React from 'react'

export default function SearchBox() {
  return (
    <div className="search" data-page="Banner Search">
        <div className="filter"></div>
        <div className="center-div">
            <div className="header-tag">
                <h2>Discover the best properties <br/> all in <span className="house-color">one place</span></h2>
                {/* <h5></h5> */}
            </div>
            <div className="search-inp">
                <div className="input-search">
                    <div className="select">
                        <input type="hidden" id="city"/>
                        <div className="city-select">
                            <div id="city-name">City</div>
                            <i className="fa-solid fa-angle-down"></i>
                            <div className="options">
                                <div id="Pune">Pune</div>
                                <div id="Mumbai">Mumbai</div>
                                <div id="Thane">Thane</div>
                            </div>
                        </div>
                    </div>
                    <div className="pip"></div>
                    <input type="text" name="search" id="search" placeholder="Search for locality, landmark, project or builder .." />
                    <div id="suggestions">
                    </div>
                </div>
            
                <button className="search" aria-label="Name">
                    <i className="sy search" >
                        <svg width="234" height="233" viewBox="0 0 234 233" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_91_988)">
                            <path d="M117.002 55C90.5345 55 69 76.5345 69 103.002C69 129.471 90.5345 151.004 117.002 151.004C143.471 151.004 165.004 129.471 165.004 103.002C165.004 76.5345 143.471 55 117.002 55ZM117.002 142.143C95.4205 142.143 77.862 124.584 77.862 103.002C77.862 81.4207 95.4205 63.862 117.002 63.862C138.584 63.862 156.142 81.4205 156.142 103.002C156.142 124.584 138.584 142.143 117.002 142.143Z" fill="#C02222"/>
                            </g>
                            <path d="M176.701 156.436L151.296 131.032C149.565 129.301 146.762 129.301 145.031 131.032C143.3 132.761 143.3 135.568 145.031 137.297L170.435 162.701C171.3 163.567 172.433 164 173.568 164C174.701 164 175.835 163.567 176.701 162.701C178.432 160.972 178.432 158.166 176.701 156.436Z" fill="#C02222"/>
                            <defs>
                            <filter id="filter0_d_91_988" x="0.874298" y="0.499439" width="232.255" height="232.256" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="13.6251"/>
                            <feGaussianBlur stdDeviation="34.0629"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_91_988"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_91_988" result="shape"/>
                            </filter>
                            </defs>
                        </svg>
                    </i>
                </button>
            </div>
        </div>
    </div>
  );
}
