import React, { useState } from 'react';

function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default function Header() {
    const [city, setCity] = useState([]);

    // Assume db is fetched from somewhere else in your code
    // and city data is stored in the state
    // const city = db.query(' SELECT DISTINCT(city) FROM `new_property` WHERE city !="" ');

    return (
        <header className="darkHeader" id="nochange" data-page="Header">
            <div className="left-header">
                <div className="logo">
                    {isMobileDevice() && (
                        <span className="i nav">
                            <img src="/images/symboles/menu.svg" className="symbole" alt="urbanpillar menu symbole" />
                        </span>
                    )}
                    <a href="/" aria-label="menu logo">
                        <img className="logo" src={process.env.REACT_APP_FILE_CDN + 'images/urban.avif'} alt="urbanpillar logo" loading="lazy" />
                    </a>
                </div>
                <div className="input-search header-search-bar">
                    <div className="select">
                        <input type="hidden" id="city" />
                        <div className="city-select">
                            <div id="city-name">City</div>
                            <i className="fa-solid fa-angle-down"></i>
                            <div className="options">
                                {city.map((item, index) => (
                                    <div key={index} id={item.city}>{item.city}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="pip"></div>
                    <input type="text" name="search" id="search" placeholder="Search for locality, landmark, project or builder .." />
                    <button className="search" aria-label="Name">
                        <i className="sy search" style={{ height: '30px' }}>
                            <svg width="234" height="233" viewBox="0 0 234 233" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_91_988)">
                                    <path d="M117.002 55C90.5345 55 69 76.5345 69 103.002C69 129.471 90.5345 151.004 117.002 151.004C143.471 151.004 165.004 129.471 165.004 103.002C165.004 76.5345 143.471 55 117.002 55ZM117.002 142.143C95.4205 142.143 77.862 124.584 77.862 103.002C77.862 81.4207 95.4205 63.862 117.002 63.862C138.584 63.862 156.142 81.4205 156.142 103.002C156.142 124.584 138.584 142.143 117.002 142.143Z" fill="#C02222"></path>
                                </g>
                                <path d="M176.701 156.436L151.296 131.032C149.565 129.301 146.762 129.301 145.031 131.032C143.3 132.761 143.3 135.568 145.031 137.297L170.435 162.701C171.3 163.567 172.433 164 173.568 164C174.701 164 175.835 163.567 176.701 162.701C178.432 160.972 178.432 158.166 176.701 156.436Z" fill="#C02222"></path>
                                <defs>
                                    <filter id="filter0_d_91_988" x="0.874298" y="0.499439" width="232.255" height="232.256" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="13.6251"></feOffset>
                                        <feGaussianBlur stdDeviation="34.0629"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_91_988"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_91_988" result="shape"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                        </i>
                    </button>
                    <div id="suggestions"></div>
                </div>
            </div>
          <div className="right-header">
              <a href="/" aria-label="menu link"><li className="active" id="Buy">Buy</li></a>
              <a aria-label="menu link" className=" likeProfile"><li id="like">Saved <div className="number" style={{ display: 'none' }}></div></li></a>
              <a className="compareProfile" aria-label="menu link " ><li id="compare">Compare <div className="number" style={{ display: 'none' }}></div></li></a>
              <button className="Addlisting">List For Free</button>
              {(sessionStorage.getItem('id') && sessionStorage.getItem('id') !== "") ? (
                  <div className="profile pc-profile">
                      <img src={process.env.REACT_APP_FILE_CDN + '/files/avatar.png'} className="avtar" alt="urbanpillar profile" />
                      <i className="fa-solid fa-angle-down arrow-profile fs-7"></i>
                  </div>
              ) : (
                  <div className="button">
                      <div className=" auto btn-logins" id="login">Log in</div>
                  </div>
              )}
              <div className="nav-menu p-2 ms-3">
                  <img src="/images/symboles/menu.svg" className="symbole" height="10px" alt="urbanpillar menu symbole" />
                  <span>Menu</span>
              </div>
          </div>
      </header>
    );
}
