import Header from './widgets/Header';
import Footer from './widgets/Footer';
import {Outlet} from 'react-router-dom'
function App() {
  return (
    <>
    <Header/>
    <Outlet/>
    <Footer/>
    </>
  );
}

export default App;
