import React, { useState, useEffect } from 'react';

function NewsFeed() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch('API_ENDPOINT_FOR_NEWSFEED_DATA');
      const data = await response.json();
      setNews(data);
    };

    fetchNews();
  }, []);

  const isImageAvailable = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  return (
    <section id="newsfeed" data-page="News & Articles">
      <div className="main-title">
        <div className="col">
          <div className="h2 heading">News & Articles</div>
          <div className="h6">Latest Property News</div>
        </div>
        <div className="col"></div>
      </div>
      <div className="newsslider">
        <div className="news-track">
          {news.map((value, index) => (
            <React.Fragment key={index}>
              {isImageAvailable(value.image) && (
                <a href={`/news/${value.id}/${value.title}`}>
                  <div className="newsfeed">
                    <div className="img-container">
                      <img src={`${process.env.FILE_CDN}${encodeURIComponent(value.image)}`} alt="urbanpillar" />
                    </div>
                    <div className="text-container">
                      <div className="title">{value.title}</div>
                      <div className="date">{new Date(value.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</div>
                    </div>
                  </div>
                </a>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="slider-nav">
        <div className="slider-right">
          <img src="/images/symboles/arrowLeft.svg" className="symboles" alt="Urbanpillar arrow" />
        </div>
        <div className="dots">
          {news.map((value, index) => (
            <div className={`nav-news ${index === 0 ? 'active' : ''}`} id={`dot${index + 1}`} key={index}></div>
          ))}
        </div>
        <div className="slider-left" data="8">
          <img src="/images/symboles/arrowRight.svg" className="symboles" alt="Urbanpillar arrow" />
        </div>
      </div>
    </section>
  );
}

export default NewsFeed;
