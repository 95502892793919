import React, { useState, useEffect } from 'react';
import SearchBox from '../widgets/Searchbar';
import NewsFeed from '../widgets/Newsfeed';
import CardV2 from '../widgets/CardV2';
import { Link } from 'react-router-dom'
const Home = () => {
  const [SessionId, setSessionId] = useState(null);
  const [property, setProperty] = useState(null);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(0);

   let leftSlide = () => {
    if (offset <= 15) {
        setOffset( offset + limit);
        console.log(offset);
        setProperty(null);
        // fetchProperty();
    }
};

let leftRight = () => {
    if (offset >= limit) {
        setOffset( offset - limit);
        console.log(offset);
        setProperty(null);
        // fetchProperty();
    }
  };







  useEffect(() => {
    const fetchProperty = async () => {
        console.log('fetching ...',`${process.env.REACT_APP_ENDPOINT}api/v1/property`);
      try {
        const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
        const response = await fetch(`${process.env.REACT_APP_ENDPOINT}api/v1/property`,{
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                "limit": limit,
                "offset": offset
              })
        }); // Assuming the API endpoint for fetching a specific news article by ID
        
        const data = await response.json();
        console.log(data.data);
        setProperty(data.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };
    if(!property){
        fetchProperty();
    }
  }, [property]);

  if (!property) {
    return <div>Loading...</div>;
  }

//   render() {
  return (<>
  <SearchBox/>
        <h1 className="d-none">UrbanPillar</h1>
        { (SessionId) ? (
            <div className="recentlyview"  data-page="Recently view" >
                <div className="main-title">
                    <div className="col">
                        <div className="h2 heading">Recently Search</div>
                        <div className="h6">Your Personalized Property Journey<br/> Uncover Your Next Chapter</div>
                    </div>
                </div>
                <div className="slider" id="recentlyview"></div>
                <div className="flex-center mt-2">
                    <button className="btn auto btn-submit mt-4">View More</button>
                </div>
            </div>
        ):(
        <div className="section" data-page="About Us">
        <div className="column">
            <div className="images">
                <div className="col-1">
                    <img loading="lazy"  className="small-height" src={process.env.REACT_APP_FILE_CDN+"images/ABOUT 1.avif"} alt="urbanpillar about image1" />
                    <img loading="lazy"  className="height-max" src={process.env.REACT_APP_FILE_CDN+"images/ABOUT 3.avif"} alt="urbanpillar about image2" />
                </div>
                <div className="col-1" style={{ marginTop: '20px' }}>
                    <img loading="lazy"  className="height-max" src={process.env.REACT_APP_FILE_CDN+"images/ABOUT 2.avif"} alt="urbanpillar about image3" />
                    <img loading="lazy"  className="small-height" src={process.env.REACT_APP_FILE_CDN+"images/ABOUT 4.avif"} alt="urbanpillar about image4" />
                </div>
            </div>
            <div className="text-back">
                <div className="h6 house-color">About Us</div>
                <h3 className="heading my-2"> Find, Buy & Own Your Dream Home </h3>
                <small className="text-light my-2"> Explore our mission to find you your dream home and the best return for your investment </small>
                <button className="explore-more ">Explore More</button>
            </div>
        </div>
        </div>
        )}

        <div className="FeaturePropety" data-page="Featured Properties">
                <div className="main-title">
            <div className="col">
                <div className="h2 heading">Featured Properties</div>
                <div className="h6">Handpicked Properties By UrbanPillar</div>
            </div>
        </div>
       <div className="slider propert_list">
       {property ? (
        // If property exists and is truthy (e.g., it's not null or undefined)
        Array.isArray(property) ? (
            // If property is an array, map over it and render a Card for each element
            property.map((item, index) => <CardV2 key={index} property={item} />)
            // <Card key={index} property={item} />
        ) : (
            // If property is not an array, render a single Card with property as data
            <CardV2 data={property} />
        )
        ) : (
        // If property does not exist or is falsy, render nothing or a loading indicator
        <img src="/images/loading.gif" alt="loading"/>
        )}

       </div>
       <div className="slider-nav pc">
           <div className="slider-right" onClick={leftRight}>
            <img loading="lazy"  src="/images/symboles/arrowLeft.svg" className="symboles" alt="Urbanpillar arrow" />
           </div>
           <div className="dots">
           <div className="nav-dot active" id="dot1"></div>
           <div className="nav-dot" id="dot2"></div>
           <div className="nav-dot" id="dot3"></div>
           <div className="nav-dot" id="dot4"></div>
           <div className="nav-dot" id="dot5"></div>
           </div>
            <div className="slider-left" onClick={leftSlide}>
                <img loading="lazy"  src="/images/symboles/arrowRight.svg" className="symboles" alt="Urbanpillar arrow" />
            </div>
                </div>
                <div className="flex-center mt-2">
                <button className="btn auto btn-submit mb view-custom">View More</button>
            </div>
        </div>
        <div className="banner" data-page="Banner">
            <img loading="lazy" src="/images/list for free.png" className="banner-images" alt="Banner" />
            <div>
            <div className="house-color h6">Owner</div>
            <div className="h2 heading">List It For <span className="house-color">Free</span></div>
            <small>List your residential or commercial property on UrbanPillar</small>
            <button className="listfroFree" > Post Property</button>
            </div>
        </div>


        <div className="FeaturePropety" data-page="Insights & Tools">
                <div className="main-title">
                    <div className="col">
                    <div className="h2 heading">Insights & Tools</div>
                    <div className="h6">Calculate and uncover your preferences</div>
                    </div>
                    <div className="col">
                    </div>
                </div>
                <div className="tools-card">
                    <Link className="tool" to="/tools/Affordability%20Calculator">
                        <div className="tool-img">
                        <img loading="lazy"  src="/images/symboles/rupee.svg" alt="urbanpillar tools" />
                        </div>
                        <h4>Affordability Calculator</h4>
                        <p className="text-light">Planning to buy a house but wondering how much home loan can you get? Now calculate your home loan affordability </p>
                    </Link>
                    <Link className="tool" to="/tools/Area%20Calculator">
                        <div className="tool-img">
                        <img loading="lazy"  src="/images/symboles/legel.svg" alt="urbanpillar tools" />
                        </div>
                        <h4>Area Calculator</h4>
                        <p className="text-light">Convert A Unit Of Measurement Into Any Other Unit</p>
                    </Link>
                    <Link className="tool" to="/tools/EMI%20Calculator">
                        <div className="tool-img">
                        <img loading="lazy"  src="/images/symboles/calculater.svg" alt="urbanpillar tools" />
                        </div>
                        <h4>EMI Calculator</h4>
                        <p className="text-light">Know How Much You Will Pay </p>
                    </Link>
                    <Link className="tool" to="/tools/Eligibility%20Calculator">
                        <div className="tool-img">
                        <img loading="lazy"  src="/images/symboles/star.svg" alt="urbanpillar tools" />
                        </div>
                        <h4>Eligibility Calculator</h4>
                        <p className="text-light">Want To Know Loan Eligibility Of The Home Loan</p>
                    </Link>
                    
                </div>
        </div>



        <div className="exploreCity" data-page="Explore City">
            <img loading="lazy"  src="/images/exploreCity.png" className="city-image" alt="urbanpillar city" />
            <div className="main-title mb">
                <div className="col">
                    <div className="h2 heading">Explore City</div>
                </div>
            </div>
            <div className="exploreCity-slider">
                <div className="float city">
                    <div className="citys" id="Pune">
                        <img loading="lazy" alt="Urbanpillar City" src={process.env.REACT_APP_FILE_CDN+"images/pune.avif"} />
                        <div className="overlay"> <span>Pune</span></div>
                    </div>
                    <div className="citys" id="Mumbai">
                        <img loading="lazy" alt="Urbanpillar City" src={process.env.REACT_APP_FILE_CDN+"images/mumbai.avif"}/>
                        <div className="overlay"> <span>Mumbai</span></div>
                    </div>
                    <div className="citys" id="Thane">
                        <img loading="lazy" alt="Urbanpillar City" src={process.env.REACT_APP_FILE_CDN+"images/thane.avif"} />
                        <div className="overlay"> <span>Thane</span></div>
                    </div>
                </div>
            </div>
        </div>
        <NewsFeed/>
        <div className="comment-sec" data-page="Customer Reviews"></div>
        </>)
//   }
}

export default Home;
