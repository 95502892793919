import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
function Property() {
  // const { id } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        fetch(`${process.env.REACT_APP_ENDPOINT}api/v1/property`,{
          method: 'post',
          body: {
           "limit": 2
          }
         })
          .then((res) => {
            console.log(res);
            return res.json();
          })
          .then((result) => {
            console.log(result.data);
            setProperty(result.data);
          });
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    fetchProperty();
  }, []);

  if (!property) {
    return <div>Loading...</div>;
  }

  return (
    <section>
      <h1>{JSON.stringify(property)}</h1>
    </section>
  );
}

export default Property;
